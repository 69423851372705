.droplist-scroll::-webkit-scrollbar-track {
    background-color: white;
    border-radius: 10px;
  }
  
.droplist-scroll::-webkit-scrollbar {
    width: 3px;
    height: 3px;
    background-color: white;
    border-radius: 10px;
}

.droplist-scroll::-webkit-scrollbar-thumb {
    background-color: #69206F;
    border-radius: 10px;
}

.droplist-scroll {
    overflow-y: auto;
    overflow-x: auto;
}