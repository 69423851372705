* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  font-size: 16px;
}

@media only screen and (min-height: 900px) and (min-width: 1600px) {
  html {
    font-size: 18px;
  }
}

/* @media only screen and (min-width: 1700px) {
  html {
    font-size: 18px;
  }
} */

[contenteditable] {
  outline: 0px solid transparent;
}

:root {
  --roster-color-1: #FFEFBA;
  --roster-color-2: #D8FAAF;
  --roster-color-3: #FAAFB0;
  --roster-color-4: #EAAFFA;
  --roster-color-5: #AFCFFA;
  --roster-color-6: #EB6BB3;
  --roster-color-7: #FCD864;
  --roster-color-8: #57F274;
  --roster-color-9: #61D2DA;
  --roster-color-10: #8274B2;
}

._2iwq {
  -webkit-animation-duration: 1s;
  -webkit-animation-fill-mode: forwards;
  -webkit-animation-iteration-count: infinite;
  -webkit-animation-name: placeHolderShimmer;
  -webkit-animation-timing-function: linear;
  background: #f6f7f8;
  background-image: -webkit-gradient(
    linear,
    left center,
    right center,
    from(#f6f7f8),
    color-stop(0.2, #edeef1),
    color-stop(0.4, #f6f7f8),
    to(#f6f7f8)
  );
  background-image: -webkit-linear-gradient(
    left,
    #f6f7f8 0%,
    #edeef1 20%,
    #f6f7f8 40%,
    #f6f7f8 100%
  );
  background-repeat: no-repeat;
  background-size: 800px 104px;
  height: 104px;
  position: relative;
}

@-webkit-keyframes placeHolderShimmer {
  0% {
    background-position: -468px 0;
  }
  100% {
    background-position: 468px 0;
  }
}
.loader {
  position: fixed;
  width: 5%;
  height: 100vh;
  /* background:#fff url("./Components/assets/gifs/802.gif") no-repeat center; */
  background-size: contain;
  z-index: 1000;
}

.Flex {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
}
.start {
  justify-content: flex-start !important;
}
.up {
  align-items: flex-start !important;
}

.Flex-row {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: row;
  width: 100;
}
.Flex-row-up {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: row;
  width: 100;
}
.g20 {
  gap: 20px;
}
.Tag {
  background-color: yellow;
}
.pointer {
  cursor: pointer;
}

.scroll::-webkit-scrollbar-track {
  background-color: white;
  border-radius: 10px;
}

.scroll::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  background-color: white;
  border-radius: 10px;
}

.scroll::-webkit-scrollbar-thumb {
  background-color: #613869;
  border-radius: 10px;
}
/* .scroll::-webkit-scrollbar-horizontal {
  height: 2px;
  background-color: #f5f5f5;
} */
.scroll {
  overflow-y: auto;
  overflow-x: auto;
}

/* .scroll:hover::-webkit-scrollbar {
  width: 5px; 
} */

.invisble-scroll::-webkit-scrollbar {
  display: none;
}

.invisble-scroll {
  overflow-y: auto;
  overflow-x: hidden;
}

.modal {
  position: absolute;
  top: 10%;
  left: 10%;
  overflow: scroll;
  height: 100%;
  display: block;
  width: 80%;
}

.scroll-X {
  /* These styles will be applied to the scrollbar on elements with this class */
  overflow-y: auto;
  overflow-x: auto;
  scrollbar-width: thin;
  -webkit-scrollbar-width: thin;
  scrollbar-color: #d5d5d5 transparent;
}

/* Additional styles for scrollbar thumb and track */
.scroll-X::-webkit-scrollbar {
  height: 4px;
  width: 4px;
  border: 1px solid #d5d5d5;
}
.scroll-X::-webkit-scrollbar-thumb {
  background-color: #d5d5d5;
  border-radius: 4px;
}
.scroll-X::-webkit-scrollbar-thumb:hover {
  background-color: #a0a0a0;
}
.scroll-X::-webkit-scrollbar-track {
  background-color: transparent;
}
.scroll-X::-webkit-scrollbar-track-piece {
  background-color: transparent;
}

/* For FIrefox */
input[type=number] {
    -moz-appearance: textfield;
}